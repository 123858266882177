:root {
    --admin-user-list-header-border-radius: 12px;
}

.admin-user-list-container {
    display: grid;
    grid-template-areas: "foo bar";
    grid-template-columns: 1fr 20rem;
    width: calc(100% + 20rem);
    transition: width 0.2s ease-out;
}

.admin-user-list-container
    .css-v70te2-MuiDataGrid-root
    .MuiDataGrid-container--top,
.css-v70te2-MuiDataGrid-root .MuiDataGrid-container--bottom {
    --DataGrid-rowBorderColor: "transparent";
}

.admin-user-list-container .css-yrdy0g-MuiDataGrid-columnHeaderRow {
    background: none !important;
}

.admin-user-list-container .MuiDataGrid-columnHeader:nth-child(2) {
    border-top-left-radius: var(--admin-user-list-header-border-radius);
}

.admin-user-list-container .MuiDataGrid-columnHeader:nth-last-child(3) {
    border-top-right-radius: var(--admin-user-list-header-border-radius);
}

.admin-user-list-container.admin-user-list-light .MuiDataGrid-columnHeader {
    background-color: rgb(215, 221, 222);
    color: #333;
}

.admin-user-list-container.admin-user-list-dark .MuiDataGrid-columnHeader {
    background-color: #333;
    color: #ffffff;
}

.admin-user-list-container .MuiDataGrid-row {
    outline: none;
}

.admin-user-list-container.admin-user-list-light .MuiDataGrid-cell {
    color: #333;
}

.admin-user-list-container.admin-user-list-dark .MuiDataGrid-cell {
    color: #ffffff;
}

.admin-user-list-container
    .css-v70te2-MuiDataGrid-root
    .MuiDataGrid-columnHeader:focus,
.css-v70te2-MuiDataGrid-root .MuiDataGrid-cell:focus {
    outline: none !important;
}

.admin-user-list-container.admin-user-list-light
    .MuiTablePagination-displayedRows {
    color: #333;
}

.admin-user-list-container.admin-user-list-dark
    .MuiTablePagination-displayedRows {
    color: #ffffff;
}

.admin-user-list-container .css-1rtad1 {
    height: 7px;
}

.admin-user-list-container .MuiDataGrid-footerContainer {
    justify-content: end;
}

.admin-user-list-container .MuiDataGrid-selectedRowCount {
    display: none;
}

.admin-user-list-header-light .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-color: #333333 !important;
}

.admin-user-list-header-dark .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-color: #e7e7e7 !important;
}

.admin-user-list-header-light
    .admin-user-list-header-search-input
    .MuiFormLabel-root {
    color: #333333 !important;
}

.admin-user-list-header-dark
    .admin-user-list-header-search-input
    .MuiFormLabel-root {
    color: #fff !important;
}
