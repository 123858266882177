:root {
    --aside-menu-width: 65px;
    --aside-menu-main-component-padding: 8px 24px 24px 24px;
    --scrollbar-width: 8px;
    --scrollbar-track-color: #f1f1f1;
    --scrollbar-thumb-color: #8a8a8a;
    --scrollbar-thumb-hover-color: #717171;
}

html,
body,
#root {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

.main-page {
    height: 100%;
    width: 100%;
}

.light-theme-text-color {
    color: #000000 !important;
}

.dark-theme-text-color {
    color: #ffffff !important;
}

.aside-menu .MuiDrawer-paper {
    width: var(--aside-menu-width) !important;
}

.aside-menu .css-zxdg2z {
    padding: var(--aside-menu-main-component-padding);
}

.aside-menu .MuiDrawer-paper {
    color: var(--aside-menu-text-color);
    background-color: var(--aside-menu-background-color);
}

.aside-menu .aside-menu-footer {
    color: var(--aside-menu-avatar-text-color);
}

.aside-menu .MuiDivider-root {
    border-color: var(--aside-menu-divider-color);
}

.aside-menu .css-1rjfce {
    border-color: var(--aside-menu-divider-color);
}

.aside-menu .css-i4bv87-MuiSvgIcon-root {
    fill: var(--aside-menu-icon-color);
}

.aside-menu .css-5ooyys-MuiSvgIcon-root {
    fill: var(--aside-menu-icon-color);
}

::-webkit-scrollbar {
    width: var(--scrollbar-width);
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(50, 50, 50, 0.1);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: var(--scrollbar-thumb-color);
    border-radius: 12px;
}

::-webkit-scrollbar-thumb:hover {
    background: var(--scrollbar-thumb-hover-color);
}
