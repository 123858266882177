@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;500&display=swap");

.central-app {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.login-light {
    background: linear-gradient(
        112.1deg,
        rgba(173, 210, 242, 1) 0%,
        rgba(255, 255, 255, 1) 50%,
        rgba(173, 210, 242, 1) 100%
    );
}

.login-dark {
    background: linear-gradient(
        112.1deg,
        rgb(32, 38, 57) 11.4%,
        rgb(63, 76, 119) 70.2%
    );
}

.login-header {
    display: flex;
    justify-content: end;
}

.recover-password-header {
    display: flex;
    justify-content: end;
}

.set-password-header {
    display: flex;
    justify-content: end;
}

.login-body {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.recover-password-body {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.set-password-body {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-body__login-form {
    display: block;
    min-width: 400px;
    width: 25%;
    height: 42.5%;
    padding: 16px;
    border-radius: 15px;
    border: 1px solid #82afdb;
    transform: translateY(-60px);
}

.recover-password__recover-password-form {
    display: block;
    min-width: 400px;
    width: 25%;
    height: 31%;
    padding: 16px;
    padding-bottom: 25px;
    border-radius: 15px;
    border: 1px solid #82afdb;
    transform: translateY(-60px);
}

.set-password__set-password-form {
    display: block;
    min-width: 400px;
    width: 25%;
    height: 47%;
    padding: 16px;
    padding-bottom: 25px;
    border-radius: 15px;
    border: 1px solid #82afdb;
    transform: translateY(-60px);
}

.login-body__login-form-light {
    background-color: rgba(255, 255, 255, 0.25);
}

.recover-password__recover-password-form-light {
    background-color: rgba(255, 255, 255, 0.25);
}

.set-password__recover-set-form-light {
    background-color: rgba(255, 255, 255, 0.25);
}

.login-body__login-form-dark {
    background-color: rgba(75, 75, 75, 0.25);
}

.recover-password__recover-password-form-dark {
    background-color: rgba(75, 75, 75, 0.25);
}

.set-password__recover-set-form-dark {
    background-color: rgba(75, 75, 75, 0.25);
}

.login-form__logo-image-container {
    width: 50%;
    height: 45px;
    margin-top: 5%;
    margin-bottom: 7%;
    margin-left: auto;
    margin-right: auto;
}

.recover-password-form__recover-password-image-container {
    display: flex;
    width: 50%;
    height: 45px;
    margin-top: 5%;
    margin-bottom: 7%;
    margin-left: auto;
    margin-right: auto;
}

.set-password-form__set-password-image-container {
    display: flex;
    width: 40%;
    min-width: 240px;
    height: 45px;
    margin-top: 2%;
    margin-bottom: 4% !important;
    margin-left: auto;
    margin-right: auto;
}

.login-form__logo-image {
    width: 200px;
    height: 100%;
}

.recover-password-form__recover-password-image {
    width: 200px;
    height: 100%;
    transform: translateX(-50px);
}

.set-password-form__set-password-image {
    width: 200px;
    height: 100%;
}

.login-form__subtitle-container {
    width: 100%;
}

.recover-password-form__subtitle-container {
    width: 100%;
}

.login-form__title {
    margin-top: 3.5%;
    margin-bottom: 1.5%;
    text-align: center;
    font-size: 20px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-style: normal;
}

.recover-password-form__title {
    margin-bottom: 1.5%;
    text-align: center;
    font-size: 20px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-style: normal;
}

.login-form__subtitle {
    margin-top: 1.5%;
    margin-bottom: 4.5%;
    text-align: center;
    font-size: 18px;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-style: normal;
}

.recover-password-form__subtitle {
    margin-top: 1.5%;
    margin-bottom: 4.5%;
    text-align: center;
    font-size: 18px;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-style: normal;
}

.login-form__container {
    display: block;
}

.recover-password-form__container {
    display: block;
}

.login-form__input-container {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.recover-password-form__input-container {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.login-form__input {
    width: 100%;
}

.recover-password-form__input {
    width: 100%;
}

.login-form__label-password {
    margin-top: 4%;
}

.login-form__configuration-container {
    width: 90%;
    margin-top: 2%;
    margin-left: 5%;
    margin-right: 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.login-form__configuration-keep-connection {
    width: 50%;
    white-space: nowrap;
}

.login-form__configuration-forgot-password {
    width: 50%;
    white-space: nowrap;
    display: flex;
    justify-content: end;
}

.login-form__configuration-forgot-password button {
    font-size: 12px !important;
}

.login-form__login-button-container {
    width: 100%;
    margin-top: 3%;
    display: flex;
    justify-content: center;
}

.login-form__login-button-container {
    width: 100%;
    margin-top: 3%;
    display: flex;
    justify-content: center;
}

.recover-password-form__recover-password-button-container {
    width: 95%;
    margin-top: 5%;
    margin-left: auto;
    display: flex;
    justify-content: center;
}

.login-form__login-button {
    width: 90%;
    margin-left: auto !important;
    margin-right: auto !important;
}

.login-form__login-button-light-theme {
    background-color: #1976d2 !important;
}

.recover-password-form__recover-password-button-light-theme {
    background-color: #1976d2 !important;
}

.login-form__login-button-dark-theme {
    background-color: #4f0dd2 !important;
}

.recover-password-form__recover-password-button-dark-theme {
    background-color: #4f0dd2 !important;
}

.login-form__input-helper {
    color: rgb(196, 13, 13) !important;
}

.login-form__input-helper-hidden {
    display: none;
}

.light-theme-text-color .css-1x51dt5-MuiInputBase-input-MuiInput-input {
    color: #000000 !important;
}

.dark-theme-text-color .css-1x51dt5-MuiInputBase-input-MuiInput-input {
    color: #ffffff !important;
}

.home-light {
    background-color: #f0f4f8;
    color: #333333;
}

.home-dark {
    background-color: #2a2a2a;
    color: #ffffff;
}
